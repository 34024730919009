import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import * as ServiceV2Reducer from '../../../../shared/state/service-v2/reducers';
import * as ServiceV2Actions from '../../../../shared/state/service-v2/actions';
import { lessThenValidator } from "src/shared/shared.validators";

@Component({
  selector: 'mi-advanced-search-filter-number-v2',
  templateUrl: './advanced-search-filter-number-v2.component.html',
  styleUrls: ['./advanced-search-filter-number-v2.component.scss']
})

export class AdvancedSearchFilterNumberV2Component extends AbstractPageComponent {
  @Input() title: string = 'Number Filter';
  @Input() filter_name: string='Number';
  @Input() min_label: string='Min';
  @Input() max_label: string='Max';
  @Input() min_placeholder: string='Min';
  @Input() max_placeholder: string='Max';
  @Input() min_prefill: number = null;
  @Input() max_prefill: number = null;
  @Input() filter_button_label: string='Apply';
  @Output() filterApplied: EventEmitter<any> = new EventEmitter<any>();

  min: number;
  max: number;
  maxname: string = "max";
  minname: string = "min";
  form: FormGroup = null;
  isVisible: boolean=true;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<ServiceV2Reducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    this.maxname = "max";
    this.minname = "min";

    let formgroup = {};
    formgroup[this.maxname] = [null];
    formgroup[this.minname] = [null];
    this.form = this.formBuilder.group(formgroup, {
      validator: lessThenValidator(this.minname, this.maxname)
    });

    this.prefillFormData();
  }

  prefillFormData() {
    let formgroupdata = {};
    // formgroupdata[this.maxname] = this.max_prefill || null;
    // formgroupdata[this.minname] = this.min_prefill || null;
    formgroupdata[this.maxname] = this.max_prefill !== null ? this.max_prefill : null;
    formgroupdata[this.minname] = this.min_prefill !== null ? this.min_prefill : null;
    this.form.setValue({...formgroupdata});
  }

  onFormSubmit(data) {
    let formgroupdata = {};
    formgroupdata[this.maxname] = data[this.maxname];
    formgroupdata[this.minname] = data[this.minname];
  
    this.filterApplied.emit(formgroupdata);
  }
}